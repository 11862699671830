import React from 'react';
import {apiService} from "../../services/apiService";
import {Link, useHistory} from 'react-router-dom';
import AdminGenerateUserTest from "./AdminGenerateUserTest";
import moment from "moment";
import {ReactSVG} from 'react-svg';
import sortUp from '../../sources/img/sort-up.svg';
import sortDown from '../../sources/img/sort-down.svg';

function AdminUserTesting() {
    let history = useHistory();
    const [userTestList, setUserTestList] = React.useState([]);
    const [categoryList, setCategoryList] = React.useState([]);
    const [isFetching, setFetching] = React.useState(false);
    const [page, setPage] = React.useState({
        currentNum: 0,
        pageSize: 20,
        pageCount: 0,
        sortBy: 'answerDate',
        ordered: '_desc'
    });

    React.useEffect(() => {
        reloadUserTestList(page);
        apiService.getTestCategoryList().then(
            response => {
                setCategoryList(response.list);
            }
        );
    }, []);


    const reloadUserTestList = (page) => {
        setFetching(true);
        apiService.getUserTests(page).then(
            r => {
                if (r.response.total > 0) {
                    setPage({...page, pageCount: Math.ceil(r.response.total / page.pageSize)});
                    setUserTestList(r.response.content);
                } else {
                    setUserTestList([]);
                }


            }
        ).finally(() => setFetching(false));
    };

    console.log('page', page);

    return (
        <div className={'container'}>
            <div className="d-flex justify-content-between mt-5">
                <h3>Тестирование пользователей</h3>
                <AdminGenerateUserTest categoryList={categoryList} onSuccess={() => reloadUserTestList(page)}/>
            </div>

            <table className="table table-hover mt-5">
                <thead>
                <tr>
                    <td scope="col">#</td>
                    <td scope="col">
                        <button className={'btn-order' + (page.sortBy === 'email' ? ' active' : '')}
                                onClick={() => {
                                    let p = page;
                                    p.sortBy = 'email';
                                    if (page.sortBy === 'email') {
                                        p.ordered = page.ordered === '_asc' ? '_desc' : '_asc';
                                    }
                                    setPage(p);
                                    reloadUserTestList(p);
                                }}>
                            <label>email</label>
                            {page.sortBy === 'email' ? (page.ordered === '_asc' ? <ReactSVG src={sortUp}/> :
                                <ReactSVG src={sortDown}/>) : ''}
                        </button>
                    </td>
                    <td scope="col">ФИО</td>
                    <td scope="col">
                      <button className={'btn-order' + (page.sortBy === 'createDate' ? ' active' : '')}
                              onClick={() => {
                                let p = page;
                                p.sortBy = 'createDate';
                                if (page.sortBy === 'createDate') {
                                  p.ordered = page.ordered === '_asc' ? '_desc' : '_asc';
                                }
                                setPage(p);
                                reloadUserTestList(p);
                              }}>
                        <label>Дата создания</label>
                        {page.sortBy === 'createDate' ? (page.ordered === '_asc' ? <ReactSVG src={sortUp}/> :
                          <ReactSVG src={sortDown}/>) : ''}
                      </button>
                    </td>
                    <td scope="col">
                        <button className={'btn-order' + (page.sortBy === 'answerDate' ? ' active' : '')}
                                onClick={() => {
                                    let p = page;
                                    p.sortBy = 'answerDate';
                                    if (page.sortBy === 'answerDate') {
                                        p.ordered = page.ordered === '_asc' ? '_desc' : '_asc';
                                    }
                                    setPage(p);
                                    reloadUserTestList(p);
                                }}>
                            <label>Дата ответа</label>
                            {page.sortBy === 'answerDate' ? (page.ordered === '_asc' ? <ReactSVG src={sortUp}/> :
                                <ReactSVG src={sortDown}/>) : ''}
                        </button>
                    </td>
                    <td scope="col">
                        <button className={'btn-order' + (page.sortBy === 'status' ? ' active' : '')}
                                onClick={() => {
                                    let p = page;
                                    p.sortBy = 'status';
                                    if (page.sortBy === 'status') {
                                        p.ordered = page.ordered === '_asc' ? '_desc' : '_asc';
                                    }
                                    setPage(p);
                                    reloadUserTestList(p);
                                }}>
                            <label>Статус</label>
                            {page.sortBy === 'status' ? (page.ordered === '_asc' ? <ReactSVG src={sortUp}/> :
                                <ReactSVG src={sortDown}/>) : ''}
                        </button>
                    </td>
                    <td scope="col">Ссылка для опрашиваемого</td>
                    <td scope="col"></td>
                </tr>
                </thead>
                <tbody>
                {isFetching && <tr>
                    <td colSpan={6}><p className={'help-text'}>Подождите, идет загрузка...</p></td>
                </tr>}
                {!isFetching && userTestList.map((uTest, i) => {
                    return <tr className="col pointer" key={i}>
                        <td>{(page.currentNum * page.pageSize) + i + 1}</td>
                        <td>{uTest.email}</td>
                        <td>{uTest.lastName + ' ' + uTest.firstName + ' ' + uTest.middleName}</td>
                        <td>{moment(uTest.createDate).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{uTest.answerDate && moment(uTest.answerDate).format('DD/MM/YYYY HH:mm')}</td>
                        <td className={uTest.status === 'Пройден' ? 'done' : ''}>{uTest.status}</td>
                        <td>
                            <Link to={'/test/' + uTest.id + '/' + uTest.accessKey} target="_blank">
                                {'/test/' + uTest.id + '/' + uTest.accessKey}
                            </Link>
                        </td>
                        <td>
                            <button className={'btn btn-sm btn-orange'} onClick={() => {
                                history.push('/admin/user_test/' + uTest.id + '/' + uTest.accessKey)
                            }}>
                                Ответы
                            </button>
                        </td>
                    </tr>;
                })}
                </tbody>
            </table>
            {!isFetching && page.pageCount > 0 &&
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center mt-5">
                    <li className="page-item disabled">
                        <a className="page-link" href="#" onClick={e => e.preventDefault()} tabindex="-1"
                           aria-disabled="true">Страницы</a>

                        {/*<a className="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>*/}
                    </li>
                    {
                        [-2, -1, 0, 1, 2].map(pIdx => {
                            let pageNum = page.currentNum + pIdx;
                            if (pageNum >= 0 && pageNum < page.pageCount) {
                                return <li key={'page' + pIdx}
                                           className={"page-item" + (page.currentNum === pageNum ? ' active' : '')}>
                                    <button title={'Go to Page #' + (pageNum + 1)} onClick={e => {
                                        e.preventDefault();
                                        let p = page;
                                        p.currentNum = pageNum;
                                        setPage(p);
                                        reloadUserTestList(p);
                                    }}
                                            className="page-link">{pageNum + 1}</button>
                                </li>;
                            }
                        })
                    }
                    {/*<li className="page-item"><a className="page-link" href="#">1</a></li>*/}
                    {/*<li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                    {/*<li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                    {/*<li className="page-item">*/}
                    {/*<a className="page-link" href="#">Next</a>*/}
                    {/*</li>*/}
                </ul>
            </nav>
            }
        </div>
    );
}

AdminUserTesting.propTypes = {};

export default AdminUserTesting;