import React from 'react';
import PropTypes from 'prop-types';

function AboutUser({onSubmit, testProps}) {

    const [testingUser, setTestingUser] = React.useState({
        firstName: '',
        middleName: '',
        lastName: '',
        age: '',
        gender: '',
        email: testProps.email
    });

    const [wasValidated, setWasValidated] = React.useState(false);

    const applyUserInfo = (validate) => {
        if (validate) {
            setWasValidated(true);
            if (testProps.methodology.includes('kettell-16') && testingUser.firstName !== '' && testingUser.lastName !== ''
                && testingUser.age !== '' && testingUser.gender !== '' && testingUser.email !== '') {
                onSubmit(testingUser);
            }else{
              if(testingUser.firstName !== '' && testingUser.lastName !== ''){
                onSubmit(testingUser);
              }
            }
        } else {
            onSubmit(testingUser);
        }
    };

    return (
        <div className={'row justify-content-center'}>
            <div className={'col col-md-6 col-sm-12 p-0'}>
                <form className={'mt-5 testing-user ' + (wasValidated ? 'was-validated' : '')}>
                    <h5>Заполните информацию о себе</h5>
                    <hr className={'mb-5'}/>
                    <div className="form-group">
                        <label htmlFor="lastName">Фамилия</label>
                        <input type="text" className="form-control" id="lastName" value={testingUser.lastName}
                               onChange={(e) => setTestingUser({...testingUser, lastName: e.target.value})} required/>
                        <div className="invalid-feedback">
                            Введите фамилию
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Имя</label>
                        <input type="text" className="form-control" id="name" value={testingUser.firstName}
                               onChange={(e) => setTestingUser({...testingUser, firstName: e.target.value})} required/>
                        <div className="invalid-feedback">
                            Введите имя
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="middleName">Отчество</label>
                        <input type="text" className="form-control" id="middleName" value={testingUser.middleName}
                               onChange={(e) => setTestingUser({...testingUser, middleName: e.target.value})}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">email</label>
                        <input type="email" className="form-control" id="email" value={testingUser.email}
                               onChange={(e) => setTestingUser({...testingUser, email: e.target.value})} required/>
                        <div className="invalid-feedback">
                            Введите email
                        </div>
                    </div>
                  {testProps.methodology.includes('kettell-16') &&
                    <div className="form-group">
                        <label htmlFor="age">Возраст</label>
                        <input type="number" className="form-control" id="age" value={testingUser.age}
                               onChange={(e) => setTestingUser({...testingUser, age: e.target.value})} required/>
                        <div className="invalid-feedback">
                            Введите Ваш возраст
                        </div>
                    </div>
                  }
                  {testProps.methodology.includes('kettell-16') &&
                    <div className="form-group">
                        <label>Пол</label>
                        <div className="form-group">
                            <div className="custom-control custom-radio ">
                                <input type="radio" id="customRadio1" name="customRadio"
                                       className="custom-control-input"
                                       onChange={() => setTestingUser({...testingUser, gender: 'м'})}
                                       checked={testingUser.gender === 'м'} required/>
                                <label className="custom-control-label" htmlFor="customRadio1">Мужской</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input type="radio" id="customRadio2" name="customRadio"
                                       className="custom-control-input"
                                       onChange={() => setTestingUser({...testingUser, gender: 'ж'})}
                                       checked={testingUser.gender === 'ж'} required/>
                                <label className="custom-control-label" htmlFor="customRadio2">Женский</label>
                                <div className="invalid-feedback">
                                    Выберите Ваш пол
                                </div>
                            </div>
                        </div>
                    </div>
                  }
                    <hr className={'mt-5 mb-4'}/>
                    <p><small>Нажимая на кнопку "Сохранить и продолжить" я подтверждаю своё согласие на передачу и обработку персональных данных.</small></p>

                    <div className={'d-flex justify-content-between'}>
                        <button type="button" onClick={() => {
                            applyUserInfo(true);
                        }} className="btn btn-orange">Сохранить и продолжить
                        </button>
                        {(!testProps.methodology.includes('kettell-16') || testProps.methodology === '') &&
                        <button type="button" onClick={() => {
                            applyUserInfo(false);
                        }} className="btn btn-orange-bordered">Пропустить
                        </button>}
                    </div>
                </form>
            </div>
        </div>
    );
}

AboutUser.propTypes = {
    onSubmit: PropTypes.func,
    testProps: PropTypes.object
};

export default AboutUser;