import React from 'react';
import {apiService} from "../../services/apiService";
import {Link, useHistory, useParams} from 'react-router-dom';
import svgTrash from '../../sources/img/trash.svg';
import {ReactSVG} from 'react-svg';
import AddQuestionForm from "./AddQuestionForm";
import {getTestMothodology} from "../../services/testMethodologyService";
import {isAdmin} from "../../services/login.service";
import moment from 'moment/moment';
import EditTestProps from '../../components/EditTestProps';

function AdminTest() {
  const {testId} = useParams();
  const [test, setTest] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  // const [isEditTestOpen, setEditTestOpen] = React.useState(false);
  const [editedQuestion, setEditedQuestion] = React.useState(null);
  // const [isQuestionEditOpened, setQuestionEditOpened] = React.useState(false);


  let history = useHistory();

  React.useEffect(() => {
    reloadTest();
  }, []);


  const reloadTest = () => {
    apiService.getTest(testId).then(
      test => {
        setTest(test);
        console.log('test', test);
        if (test !== null) {
          apiService.getTestCategory(test.categoryId).then(
            category => {
              setCategory(category);
            }
          );
          apiService.getQuestions(testId).then(
            questions => {
              console.log('questions', questions);
              setQuestions(questions.list);
            }
          );
        }

      }
    )
  };

  const reloadQuestionList = () => {
    console.log('reloadQuestionList');
    apiService.getQuestions(testId).then(
      questions => {
        console.log('questions', questions);
        setQuestions(questions.list);
      }
    );
  };

  const deleteTest = () => {
    if (window.confirm('Вы уверены, что хотите удалить данный тест?')) {
      apiService.deleteTest(testId, category.id).then(
        response => {
          console.log(response);
          if (response.status === 'SUCCESS') {
            history.push('/admin/tests/test_category/' + category.id);
          }
        }
      );
    }
  };

  const editTest = (t) => {
    console.log('newTest', t);
    setTest(t);
    apiService.addTest(t, category.id)
      .then(() => {
        reloadTest();
      }, () => {
        reloadTest();
        alert('Не удалось отредактировать тест!');
      });
      // .finally(() => setEditTestOpen(false));
  };

  const deleteQuestion = (questionId) => {
    if (window.confirm('Вы уверены, что хотите удалить этот вопрос?')) {
      apiService.deleteQuestion(questionId, testId).then(
        result => {
          if (result.status === 'SUCCESS') {
            reloadQuestionList();
          } else {
            alert('Не удалось удалить вопрос!');
          }
        }, () => alert('Не удалось удалить вопрос!')
      )
    }
  };

  const saveQuestion = () => {
    console.log(editedQuestion);
    if (editedQuestion.question === '') {
      alert('Текст вопроса не может быть пустым! Введите вопрос.');
    } else if (editedQuestion.answerOptions.length < 2) {
      alert('Добавьте хотя бы два варианта ответа!');
    } else {
      const q = {
        id: editedQuestion.id,
        testId: testId,
        question: editedQuestion.question,
        answerOptions: JSON.stringify(editedQuestion.answerOptions),
        parameters: JSON.stringify(editedQuestion.parameters)
      };
      apiService.addQuestion(q).then(
        response => {
          console.log(response);
          if (response.status === 'SUCCESS') {
            setEditedQuestion(null);
          }
        }, error => {
          alert('Не удалось изменить вопрос!');
        }).finally(() => {
        // setQuestionEditOpened(false);
        reloadQuestionList();
      });
    }
  };

  return (<>
    {test && category &&
    <>
      <div className="container-fluid bg-white">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white">
              <li className="breadcrumb-item">
                <Link to="/admin/tests/test_categories">Категории тестов</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={'/admin/tests/test_category/' + test.categoryId}>{category.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">{test.name}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className={'container'}>
        <div className={'col'}>
          <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
            <h3>{test.name}</h3>
            {isAdmin() && <div className="d-flex">
              <EditTestProps adminTest={test} onChange={(t) => editTest(t)}/>
              <button type="button" className="icon-btn ml-3" onClick={() => deleteTest()}
                      title="Удалить тест">
                <ReactSVG src={svgTrash}/>
              </button>
            </div>}
          </div>
          <p>Методика оценки: <span className="text-muted">{getTestMothodology(test.methodology).name}</span></p>
          <p>Ограничение по времени: <span
            className="text-muted">{test.timeout > 0 ? moment(test.timeout * 1000).utcOffset(0).format('HH:mm:ss') : 'нет'}</span>
          </p>
          <p>Описание теста: <span className="text-muted">{test.description}</span></p>
        </div>
        <hr/>
        <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
          <h5>Список вопросов</h5>
          {isAdmin() && <AddQuestionForm key={'addQuestionComponent'} test={test} onFinish={(isFinished) => {
            if (isFinished) {
              reloadQuestionList();
            }
          }}/>}
        </div>
        <table className="table table-hover">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Вопрос</th>
            <th scope="col">Варианты ответа <strong>(баллы)</strong></th>
            {test.methodology === 'kettell-16' && <th scope="col">Шкала</th>}
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          {questions.length === 0 && <tr>
            <td colSpan="4">Список вопросов пуст</td>
          </tr>}
          {questions.length > 0 &&
          questions.map((q, i) => {
            const answerOptions = JSON.parse(q.answerOptions);
            const qParams = JSON.parse(q.parameters);
            return <tr className="col" key={i}>
              <td>{i + 1}</td>
              <td>{q.question}</td>
              <td>
                <div className="answerOptions">
                  {answerOptions.map((ao, j) => {
                    return <p className={'answer-option'} key={j}>
                      {ao.answer}
                      <strong>{'(' + ao.value + ')'}</strong>
                    </p>
                  })}
                </div>
              </td>
              {test.methodology === 'kettell-16' &&
              <td>
                {qParams && qParams.scale}
              </td>}
              <td>
                {isAdmin() && <div className="d-flex">
                  <AddQuestionForm key={'qEdit' + i} test={test}
                                   editedQuestion={{...q, answerOptions: answerOptions, parameters: qParams}}
                                   onFinish={() => {
                                     // setQuestionEditOpened(false);
                                     reloadQuestionList();
                                   }} isEditMode={true}/>
                  {/*<button type="button" title="Редактировать вопрос" className="edit-btn"*/}
                  {/*onClick={() => {*/}
                  {/*setEditedQuestion({...q, answerOptions: answerOptions, parameters: qParams});*/}
                  {/*setQuestionEditOpened(true);*/}
                  {/*}}>*/}
                  {/*</button>*/}
                  <button type="button" title="Удалить вопрос" className="trash-btn"
                          onClick={() => deleteQuestion(q.id)}></button>
                </div>}
              </td>
            </tr>
          })
          }
          </tbody>
        </table>
      </div>

    </>
    }
  </>);
}

AdminTest.propTypes = {};

export default AdminTest;