import './App.css';
import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import MainTestPage from "./containers/MainTestPage";
import 'bootstrap/dist/css/bootstrap.css';
import './sources/styles/main-style.css';
import Header from "./containers/AdminPage/Header";
import AdminTestCategories from "./containers/AdminPage/AdminTestCategories";
import AdminTestCategory from "./containers/AdminPage/AdminTestCategory";
import AdminTest from "./containers/AdminPage/AdminTest";
import AdminUserTesting from "./containers/AdminPage/AdminUserTesting";
import AdminUserTest from "./containers/AdminPage/AdminUserTest";
import {loginService} from "./services/login.service";
import LoginPage from "./containers/LoginPage";
// import {createStore} from 'redux';
// import {Provider} from 'react-redux';
//
// const store = createStore();

function App() {

    return (
            <Router>
                <Switch>
                    <Route path={'/test/:testId/:accessKey'} component={MainTestPage}/>
                    <Route path={'/login'} component={LoginPage}/>
                    <MainApp path={'/'}>
                        <Route path={'/admin'}>
                            <Header/>
                            <Switch>
                                <Route path={'/admin/tests/test_categories'} component={AdminTestCategories} default/>
                                <Route path={'/admin/tests/test_category/:categoryId'} component={AdminTestCategory}/>
                                <Route path={'/admin/tests/test/:testId'} component={AdminTest}/>
                                <Route path={'/admin/user_testing'} component={AdminUserTesting}/>
                                <Route path={'/admin/user_test/:userTestId/:accessKey'} component={AdminUserTest}/>
                            </Switch>
                        </Route>
                    </MainApp>
                </Switch>
            </Router>
    );
}

function MainApp({children, ...rest}) {
    return (
        <Route {...rest}
               render={({location}) => {
                   const currentUser = loginService.getCurrentUser();
                   if (currentUser) {
                       return <>
                           {children}
                       </>;
                   } else {
                       return <Redirect
                           to={{
                               pathname: '/login',
                               state: {from: location}
                           }}
                       />;
                   }
               }}
        />
    );
}

export default App;
