import React from 'react';
import {apiService} from "../../services/apiService";
import {Link, useHistory, useParams} from 'react-router-dom';
import Modal from "../../components/Modal";
import svgPlus from '../../sources/img/plus.svg';
import svgPencil from '../../sources/img/pencil.svg';
import svgTrash from '../../sources/img/trash.svg';
import svgArrow from '../../sources/img/arrow-right.svg';
import {ReactSVG} from 'react-svg';
import SelectTestMethodology from "../../components/SelectTestMethodology";
import {isAdmin} from "../../services/login.service";

function AdminTestCategory() {
    const {categoryId} = useParams();
    const [category, setCategory] = React.useState(null);
    const [isAddTestOpen, setAddTestOpen] = React.useState(false);
    const [isEditCategoryOpen, setEditCategoryOpen] = React.useState(false);
    // const [error, setError] = React.useState('');
    let history = useHistory();

    const [newTest, setNewTest] = React.useState({name: '', description: '', methodology: ''});

    React.useEffect(() => {
        reloadTestCategory();
    }, []);


    const reloadTestCategory = () => {
        apiService.getTestCategory(categoryId).then(
            response => {
                setCategory(response);
                console.log('tCategory', response);
            }
        )
    };

    const deleteCategory = () => {
        if (window.confirm('Вы уверены, что хотите удалить данную категорию тестов?')) {
            apiService.deleteTestCategory(categoryId).then(
                response => {
                    console.log(response);
                    if (response.status === 'SUCCESS') {
                        history.push('/admin/tests/test_categories');
                        //return <Redirect to='/admin/test_categories'/>
                    }
                }
            );
        }
    };

    const addTest = () => {
        console.log('newTest', newTest);
        apiService.addTest(newTest, categoryId)
            .then(response => {
                history.push('/admin/tests/test/' + response.response.id);
                console.log(response);
            }, error => {
                console.log(error);
                alert('Не удалось добавить категорию!');
            })
            .finally(() => setAddTestOpen(false));
    };


    const editCategory = () => {
        apiService.addTestCategory(category)
            .then(() => {
                reloadTestCategory();
            }, () => {
                reloadTestCategory();
                alert('Не удалось добавить категорию!');
            })
            .finally(() => setEditCategoryOpen(false));
    };

    return (<>
            {category && <div className="container-fluid bg-white">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb bg-white">
                            <li className="breadcrumb-item">
                                <Link to="/admin/tests/test_categories">Категории тестов</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{category.name}</li>
                        </ol>
                    </nav>
                </div>
            </div>}
            {category &&
            <div className="container">
                <div className={'col'}>
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                        <h3>{category.name}</h3>
                        {isAdmin() && <div className="d-flex">
                            <button type="button" className="icon-btn ml-3" onClick={() => setEditCategoryOpen(true)}>
                                <ReactSVG src={svgPencil}/>
                            </button>
                            {categoryId !== 0 && <button type="button" className="icon-btn ml-3"
                                                         onClick={() => deleteCategory()} title="Удалить категорию">
                                <ReactSVG src={svgTrash}/>
                            </button>}
                        </div>}
                    </div>
                    <p className="text-muted">{category.description}</p>
                </div>
                <hr/>

                <div className={'col'}>
                    <div className="d-flex align-items-center mt-3">
                        {category.testList.length > 0 && <h5>Список тестов</h5>}
                        {category.testList.length === 0 && <h5>Список тестов пока пуст</h5>}
                        {isAdmin() && <button type="button" className="btn btn-orange-bordered ml-2"
                                onClick={() => setAddTestOpen(true)}>
                            <ReactSVG src={svgPlus}/>
                            Создать тест
                        </button>}
                    </div>
                </div>
                <div className="row row-cols-auto mt-5">
                    {category.testList.map((test, i) => {
                        return <div className="col-md-auto col-xs-12" key={i}>
                            <div className="test-category m-2 bg-light p-2">
                                <h5>{test.name}</h5>
                                <p className="text-muted">{test.description}</p>
                                <Link className="btn btn-orange align-self-end" to={'/admin/tests/test/' + test.id}>
                                    Перейти
                                    <ReactSVG src={svgArrow}/>
                                </Link>
                            </div>
                        </div>
                    })}

                </div>


                <Modal isClosable={true} title={'Добавление теста'} isOpen={isAddTestOpen} isCustomBody={false}
                       onCancel={() => setAddTestOpen(false)} footer={false}>
                    <form>
                        <div className="modal-body">

                            <div className="form-group">
                                <label htmlFor="name">Введите название теста</label>
                                <input type="text" className="form-control"
                                       onChange={(e) => setNewTest({...newTest, name: e.target.value})}
                                       value={newTest.name}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="desc">Опишите тест</label>
                                <input type="text" className="form-control"
                                       onChange={(e) => setNewTest({...newTest, description: e.target.value})}
                                       value={newTest.description}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="desc">Методика оценки</label>
                                <SelectTestMethodology methodology={newTest.methodology} onChange={method => setNewTest({...newTest, methodology: method})}/>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={() => setAddTestOpen(false)}>Отмена
                            </button>
                            <button type="button" className="btn btn-orange" onClick={() => addTest()}>Создать тест
                            </button>
                        </div>
                    </form>
                </Modal>

                <Modal isClosable={true} title={'Редактирование категории тестов'} isOpen={isEditCategoryOpen}
                       isCustomBody={false}
                       onCancel={() => setEditCategoryOpen(false)} footer={false}>
                    <form>
                        <div className="modal-body">

                            <div className="form-group">
                                <label htmlFor="name">Введите название категории</label>
                                <input type="text" className="form-control"
                                       onChange={(e) => setCategory({...category, name: e.target.value})}
                                       value={category.name}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="desc">Опишите категорию тестов</label>
                                <input type="text" className="form-control"
                                       onChange={(e) => setCategory({...category, description: e.target.value})}
                                       value={category.description}/>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={() => {
                                        reloadTestCategory();
                                        setEditCategoryOpen(false);
                                    }}>Отмена
                            </button>
                            <button type="button" className="btn btn-orange" onClick={() => editCategory()}>Применить
                            </button>
                        </div>
                    </form>
                </Modal>
            </div>}
        </>
    );
}

AdminTestCategory.propTypes = {};

export default AdminTestCategory;