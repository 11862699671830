import {KETTELL_SCAN, SCALE_TEMPLATE} from "../constants/kettellScans";

export const TEST_METHODOLOGY = [
  {
    value: '',
    name: 'Без методики'
  },
  {
    value: 'kettell-16',
    name: '16-ти факторный опросник Кеттелла'
  },
  {
    value: 'standard',
    name: 'Стандарт (кол-во правильных ответов)'
  }
];

export const getTestMothodology = (method) => {
  if (!method) {
    return TEST_METHODOLOGY[0];
  } else {
    let idx = TEST_METHODOLOGY.findIndex(el => el.value === method);
    return TEST_METHODOLOGY[idx];
  }
};

export const checkMiddleQuestions = (answerList) => {
  let middleAnswerCount = 0;
  answerList.map(answer => {
    let qScale = JSON.parse(answer.question.parameters).scale;
    if (Number.parseInt(answer.answerValue) === 1 && qScale !== 'B') {
      middleAnswerCount++;
    }
  });
  return middleAnswerCount;
};

export const calculateKettelResultsTable = (answerList, userTest) => {
  let resultTbl = [];
  SCALE_TEMPLATE.map((scale, i) => {
    let summ = 0;
    answerList.map((answer) => {
      let qScale = answer.question.parameters.scale;
      if (qScale && qScale === scale) {
        summ += answer.answerValue;
      }
    });

    let scanTables = KETTELL_SCAN[userTest.gender === 'м' ? 0 : 1].agePeriod[(userTest.age >= 19 && userTest.age <= 28) ? 0 : 1].scales;
    let result = scanTables[i].values.charAt(summ);
    resultTbl.push({scale: scale, summ: summ, result: (result === '' ? 0 : (Number.parseInt(result) + 1))})
  });


  // let scanTables = KETTELL_SCAN[userTest.gender === 'м' ? 0 : 1].agePeriod[(userTest.age >= 19 && userTest.age <=28) ? 0 : 1].scales;
  // resultTbl.map((el,i) => {
  //     let result = scanTables[i].values.charAt(el.summ);
  //     console.log(el.summ, result);
  //     el.result = result === '' ? 0 : (Number.parseInt(result) + 1);
  // });

  return resultTbl;
};