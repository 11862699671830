import React from 'react';
import {apiService} from "../../services/apiService";
import Modal from "../../components/Modal";
import svgPlus from '../../sources/img/plus.svg';
import svgArrow from '../../sources/img/arrow-right.svg';
import {ReactSVG} from 'react-svg';
import {Link} from "react-router-dom";
import {isAdmin} from "../../services/login.service";

function AdminTestCategories() {

    const [categoryList, setCategoryList] = React.useState([]);
    const [isAddOpen, setAddOpen] = React.useState(false);
    // const [error, setError] = React.useState('');

    const [newCategory, setNewCategory] = React.useState({name: '', description: ''});

    React.useEffect(() => {
        reloadCategoryList();
    }, []);


    const reloadCategoryList = () => {
        apiService.getTestCategoryList().then(
            response => {
                setCategoryList(response.list);
                console.log('cList', response);
            }
        )
    };

    const addCategory = () => {
        apiService.addTestCategory(newCategory)
            .then(response => {
                reloadCategoryList();
                console.log(response);
            }, error => {
                console.log(error);
                alert('Не удалось добавить категорию!');
            })
            .finally(() => setAddOpen(false));
    };

    return (
        <div className="container">

            <div className="d-flex flex-row mt-5">
                <h3>Категории тестов</h3>
                {isAdmin() && <button type="button" className="btn btn-orange-bordered ml-5" data-toggle="modal"
                        data-target="#addTestCategoryForm" onClick={() => setAddOpen(!isAddOpen)}>
                    <ReactSVG src={svgPlus}/>
                    Добавить категорию
                </button>}
            </div>

            {categoryList && categoryList.map((category, i) => {
                return <div className="row mt-5" key={i}>
                    <div className="col col-xs-12" >
                        <div className="test-category">
                            <div className="d-flex flex-column">
                                <h5>{category.name}</h5>
                                <p className="text-muted">{category.description}</p>
                            </div>
                            <div className={'d-flex flex-row-reverse'}>
                                <Link className="btn btn-orange"
                                   to={'/admin/tests/test_category/' + category.id}>
                                    Перейти
                                    <ReactSVG src={svgArrow}/>
                                </Link>
                                <span style={{padding: '0.4rem 2rem', height: '38px'}}>Тестов: <small
                                    className="text-muted">{category.testList.length}</small></span>
                            </div>
                        </div>
                    </div>
                </div>;
            })}


            <Modal isClosable={true} title={'Добавление категории тестов'} isOpen={isAddOpen} isCustomBody={false}
                   onCancel={() => setAddOpen(false)} footer={false}>
                <form>
                    <div className="modal-body">

                        <div className="form-group">
                            <label htmlFor="name">Введите название категории</label>
                            <input type="text" className="form-control"
                                   onChange={(e) => setNewCategory({...newCategory, name: e.target.value})}
                                   value={newCategory.name}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc">Опишите категорию тестов</label>
                            <input type="text" className="form-control"
                                   onChange={(e) => setNewCategory({...newCategory, description: e.target.value})}
                                   value={newCategory.description}/>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={() => setAddOpen(false)}>Отмена
                        </button>
                        <button type="button" className="btn btn-orange" onClick={() => addCategory()}>Добавить
                            категорию
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

AdminTestCategories.propTypes = {};

export default AdminTestCategories;