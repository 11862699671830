import React from 'react';
import PropTypes from 'prop-types';
import {SCALE_TEMPLATE} from "../../../constants/kettellScans";
import {SizeMe} from 'react-sizeme';

import {calculateKettelResultsTable} from "../../../services/testMethodologyService";
import {Bar, CartesianGrid, ComposedChart, LabelList, Legend, Line, Tooltip, XAxis, YAxis} from "recharts";

function KettellResultsChart({answerList, userTest}) {

    const [resultTableKettell, setResultTableKettel] = React.useState(SCALE_TEMPLATE);

    React.useEffect(() => {
        setResultTableKettel(calculateKettelResultsTable(answerList, userTest));
    },[]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p><b>Шкала {payload[0].payload.scale}</b></p>
                    <p>Сырая сумма: {payload[0].payload.summ}</p>
                    <p>Результат: {payload[0].payload.result}</p>

                </div>
            );
        }

        return null;
    };


    return (
        <SizeMe>{({size}) =>
        <div>
            <h5>16-ти факторный опросник Кеттелла</h5>
            <h6 className={'mb-5'}>График ответов по методике</h6>
            <ComposedChart width={size.width} height={400} data={resultTableKettell}>
                <XAxis dataKey="scale"/>
                <YAxis/>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                    payload={[{value:'Сырая сумма баллов', id: 'summ', type: 'square', color: '#808080'}, {value:'Результат', id: 'result', type: 'square', color: '#FF8C00'}]}
                />
                <CartesianGrid stroke="#f5f5f5"/>
                {/*<Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" ></Area>*/}
                <Line dataKey="summ" type="monotone" stroke="#808080"/>
                <Bar dataKey="result" barSize={20} fill="#FF8C00">
                    <LabelList dataKey="result" position="top" stroke={"#FF8C00"} />
                </Bar>
                {/*<Line type="monotone" dataKey="uv" stroke="#ff7300" />*/}
            </ComposedChart>
        </div>}
        </SizeMe>
    );
}

KettellResultsChart.propTypes = {
    answerList: PropTypes.array,
    userTest: PropTypes.object
};

export default KettellResultsChart;