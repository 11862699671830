import React from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import svgPencil from '../sources/img/pencil.svg';
import Modal from './Modal';
import SelectTestMethodology from './SelectTestMethodology';

function EditTestProps({adminTest, onChange}) {
  const [isOpen, setEditTestOpen] = React.useState(false);
  const [test, setTest] = React.useState(adminTest);

  return (
    <>
      <button type="button" className="icon-btn ml-3" onClick={() => setEditTestOpen(true)}>
        <ReactSVG src={svgPencil}/>
      </button>
      <Modal isClosable={true} title={'Редактирование теста'} isOpen={isOpen}
             isCustomBody={false}
             onCancel={() => setEditTestOpen(false)} footer={false}>
        <form>
          <div className="modal-body">

            <div className="form-group">
              <label htmlFor="name">Введите название теста</label>
              <input type="text" className="form-control"
                     onChange={(e) => setTest({...test, name: e.target.value})}
                     value={test.name}/>
            </div>
            <div className="form-group">
              <label htmlFor="desc">Методика оценки</label>
              <SelectTestMethodology methodology={test.methodology} onChange={method => setTest({...test, methodology: method})}/>
            </div>
            <div className="form-group">
              <label htmlFor="timeout">Ограничение по времени (секунд)</label>
              <input type="number" className="form-control"
                     onChange={(e) => setTest({...test, timeout: e.target.value})}
                     value={test.timeout}/>
              <small>0 - без ограничений</small>
            </div>
            <div className="form-group">
              <label htmlFor="desc">Опишите тест</label>
              <input type="text" className="form-control"
                     onChange={(e) => setTest({...test, description: e.target.value})}
                     value={test.description}/>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                    onClick={() => {
                      setEditTestOpen(false);
                      setTest(adminTest);
                    }}>Отмена
            </button>
            <button type="button" className="btn btn-orange" onClick={() => {
              onChange(test);
              setEditTestOpen(false);
            }}>Применить
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

EditTestProps.propTypes = {
  adminTest: PropTypes.object,
  onChange: PropTypes.func
};

export default EditTestProps;