import React from 'react';
import {useParams} from 'react-router-dom';
import AboutUser from "./AboutUser";
import Carousel from './Carousel';

import {apiService} from "../services/apiService";
import moment from 'moment';

function MainTestPage() {

  const {testId, accessKey} = useParams();
  const [testingUser, setTestingUser] = React.useState(null);
  const [userTest, setUserTest] = React.useState(null);
  const [isFinished, setIsFinished] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [originalTest, setOriginalTest] = React.useState(null);
  const [showInstruction, setShowInstruction] = React.useState(false);
  const [showTimeoutWarning, setShowTimeoutWarning] = React.useState(false);

  const [questionList, setQuestionList] = React.useState([]);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    setFetching(true);
    apiService.getUserTestForUser(testId, accessKey)
      .then(uTest => {
        setUserTest(uTest);
        if (uTest.status === 'Новый') {
          apiService.getUserTestQuestionnaire(uTest.id).then(
            response => {
              Promise.all(
                response.list.map(userAnswer => {
                  return apiService.getUserTestQuestionForUser(userAnswer.questionId)
                    .then(q => {
                      let question = null;
                      if (q) {
                        question = {...q, answerOptions: JSON.parse(q.answerOptions)}
                      }
                      return {...userAnswer, question: question};
                    })
                })
              ).then(answerList => {
                return answerList.filter(el => el.question !== null)
              }).then(qList => {
                setQuestionList(qList);
                apiService.geSourceTest(qList[0].testId).then(
                  origTest => {
                    setOriginalTest(origTest)
                  }
                );
                setFetching(false);
              });
            }
          )
        } else if (uTest.status !== 'Новый') {
          setIsFinished(true);
          setFetching(false);
        } else {
          setFetching(false);
          setError('Список вопросов пуст или тест не найден!');
        }
      })
  }, []);

  const sendTest = () => {
    let test = userTest;
    test.firstName = testingUser.firstName;
    test.middleName = testingUser.middleName;
    test.lastName = testingUser.lastName;
    test.age = testingUser.age;
    test.gender = testingUser.gender;
    test.email = testingUser.email;
    test.answerDate = moment();
    test.status = 'Пройден';

    apiService.sendUserTest(test, questionList, accessKey).then(
      response => {
        if (response.status === 'SUCCESS') {
          setIsFinished(true);
        } else {
          alert('Не удалось отправить тест на проверку!');
        }
      }, () => alert('Не удалось отправить тест на проверку!')
    )


  };

  return (
    <div className={'container-fluid'}>
      <div className={'row test-me-header'}>
        <div className={'container'}>
          <div className={'row justify-content-center'}>
            <div className={'col col-md-6 col-sm-12 p-0'}>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div className={'logo-2 ml-1'}/>
                <h3 className={'logo-text'}>Дистанционная система опросов</h3>
                {process.env.IS_DEV === 'true' && <smal>d</smal>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'container'}>
        <div className={'test-me-container'}>
          {isFetching && <div className={'row justify-content-center'}>
            <div className={'col col-md-6 col-sm-12 p-0'}>
              <div className="carousel" style={{maxWidth: '600px', margin: '2rem auto'}}>
                <p className="text-muted mt-3">Подождите пожалуйста,</p>
                <p className="text-muted mt-3">Идет загрузка...</p>
              </div>
            </div>
          </div>}
          {error !== '' &&
          <div className="alert alert-danger mt-5" role="alert">
            {error}
          </div>
          }
          {isFinished &&
          <div className={'row justify-content-center'}>
            <div className={'col col-md-6 col-sm-12 p-0'}>
              <div className="carousel" style={{maxWidth: '600px', margin: '2rem auto'}}>
                <h6>Ваши ответы успешно отправлены на анализ</h6><br/>
                <h5>О результатах тестирования вы узнаете позднее.</h5><br/>
                <h3 style={{color: 'orange'}}>Спасибо, что уделили время!</h3>
              </div>
            </div>
          </div>}
          <div>
            {!testingUser && originalTest && <AboutUser testProps={userTest} onSubmit={(user) => {
              setTestingUser(user);
              if (userTest.methodology.includes('kettell-16')) {
                setShowInstruction(true);
              }
              if (originalTest.timeout > 0) {
                setShowTimeoutWarning(true);
              }
            }}/>}
            {testingUser && !showInstruction && !showTimeoutWarning && !isFinished &&
            <div>
              <Carousel timeout={originalTest.timeout} userTest={userTest} questionList={questionList}
                        onChange={qList => {
                          setQuestionList(qList);
                        }} onFinish={() => sendTest()}/>
            </div>}

          </div>
          {showInstruction && originalTest && originalTest.instruction &&
          <div className={'row justify-content-center'}>
            <div className={'col col-md-6 col-sm-12 p-0'}>
              <div className="carousel" style={{maxWidth: '600px', margin: '2rem auto'}}>
                <h5>Инструкция для прохождения опроса</h5>
                <p className={'mt-3'} style={{lineHeight: '2rem', fontSize: '90%'}}>{originalTest.instruction}</p>
                <hr/>
                <button type={'button'} className={'btn btn-orange'} onClick={() => setShowInstruction(false)}>Понятно
                </button>
              </div>
            </div>
          </div>
          }
          {!showInstruction && showTimeoutWarning &&
          <div className={'row justify-content-center'}>
            <div className={'col col-md-6 col-sm-12 p-0'}>
              <div className="carousel" style={{maxWidth: '600px', margin: '2rem auto'}}>
                <h5>Внимание! Ограничение по времени!</h5>
                <p className={'mt-3'} style={{lineHeight: '2rem', fontSize: '90%'}}>
                  У данного теста есть ограничение по времени
                </p>
                <br/>
                <h3>{moment(originalTest.timeout * 1000).utcOffset(0).format('HH:mm:ss')}</h3>

                <hr/>
                <button type={'button'} className={'btn btn-orange'} onClick={() => {
                  setShowTimeoutWarning(false);
                }}>
                  Приступить
                </button>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
}

export default MainTestPage;