import moment from "moment";
import {getCurrentUser} from "./login.service";

// const HOST = 'http://localhost:8080';
const HOST = 'https://prof-opros.ru';
// const HOST = 'http://84.38.183.183:8080';
const ADMIN_DOMAIN_URL = HOST + '/api/admin';
const USER_ADMIN_DOMAIN_URL = HOST + '/api/user';

function getTestCategoryList() {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test_categories', config);
}

function getTestCategory(categoryId) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test_category/' + categoryId, config)
        .then(resp => {
            return resp.response;
        });
}

function addTestCategory(newCategory) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(newCategory)
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/add_test_category', config);
}

function addTest(newTest, categoryId) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(newTest)
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test_category/' + categoryId + '/add_test', config);
}

function getTest(testId) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test/' + testId, config).then(resp => {
        return resp.response;
    });
}

function deleteTest(testId, categoryId) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test_category/' + categoryId + '/delete_test?test_id=' + testId, config);
}

function getQuestions(testId) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test/' + testId + '/question_list', config);
}


function deleteTestCategory(categoryId) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test_category/' + categoryId + '/delete_category', config);
};

function addQuestion(question) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(question)
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test/' + question.testId + '/add_question', config);
}

function deleteQuestion(questionId, testId) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/test/' + testId + '/delete_question?id=' + questionId, config);
}

function getUserTests(page) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/user_test/all/?pageNo=' + page.currentNum + '&pageSize=' + page.pageSize + '&sortBy=' + (page.sortBy + page.ordered), config);
}


function fetchURL(url, config, accessFree) {
    let conf = {...config};
    if (!accessFree) {
        conf = {
            ...config,
            headers: {
                ...config.headers,
                Authorization: 'Basic ' + getCurrentUser().authLine
            }
        };
    };
    return fetch(url, conf)
        .then(response => {
            const jResponse = response.json();
            return jResponse;
        })
        .then(jResponse => {
            if (jResponse.status !== 'SUCCESS') {
                return Promise.reject((jResponse.errorCode || '') + (jResponse.errorMsg || '') + '');
            }
            return jResponse;
        });
}


function generateUserTest(email, usedTests) {
    const formData = new FormData();
    formData.append('usedTests', usedTests);
    formData.append('email', email);
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'multipart/form-data'
        },
        body: formData
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/generate_user_test', config);
}

function getUserTestForUser(testId, accessKey) {
    const config = {
        method: 'GET',
        headers: {
            // 'access_key': accessKey,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(USER_ADMIN_DOMAIN_URL + '/user_test?id=' + testId + '&access_key=' + accessKey, config, true).then(resp => {
        if (resp.status === 'SUCCESS') {
            return {...resp.response, createDate: moment(resp.response.createDate).format('yyyy-MM-DD HH:mm:ss')}
        }
        return resp.response;
    });
}

function geSourceTest(testId) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(USER_ADMIN_DOMAIN_URL + '/source_test/' + testId, config, true).then(resp => {
        return resp.response;
    });
}


function getUserTestQuestionForUser(questionId) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(USER_ADMIN_DOMAIN_URL + '/question?id=' + questionId, config, true).then(resp => {
        return resp.response;
    });
}

function sendUserTest(test, questionList, accessKey) {
    const config = {
        method: 'POST',
        headers: {
            // 'access_key': accessKey,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(test)
    };
    return fetchURL(USER_ADMIN_DOMAIN_URL + '/save_user_test?id=' + test.id + '&access_key=' + accessKey, config, true);
        // .then(response => {
        //     if (response.status === 'SUCCESS') {
        //         const config2 = {
        //             method: 'POST',
        //             headers: {
        //                 'Access-Control-Allow-Origin': '*',
        //                 'Content-Type': 'application/json;charset=UTF-8'
        //             },
        //             body: JSON.stringify(questionList)
        //         };
        //         return fetchURL(USER_ADMIN_DOMAIN_URL + '/save_user_answers', config2, true);
        //     } else {
        //         return Promise.reject('ERROR!');
        //     }
        // });
}

function saveUserAnswer(userAnswer) {
  const config = {
    method: 'POST',
    headers: {
      // 'access_key': accessKey,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(userAnswer)
  };
  return fetchURL(USER_ADMIN_DOMAIN_URL + '/save_user_answer', config, true);
}

function updateUserTest(test, accessKey) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(test)
    };
    return fetchURL(USER_ADMIN_DOMAIN_URL + '/save_user_test?id=' + test.id + '&access_key=' + accessKey, config, true);
}

function getUserTestQuestionnaire(userTestId) {
    const config = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(USER_ADMIN_DOMAIN_URL + '/user_test_questionnaire?id=' + userTestId, config, true);
}

function deleteUserTest(userTest) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/user_test/' + userTest.id + '/' + userTest.accessKey + '/delete', config);
}

function sendUserTestEmail(userTest) {
    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };
    return fetchURL(ADMIN_DOMAIN_URL + '/user_test/' + userTest.id + '/' + userTest.accessKey + '/send_email', config);
}

function checkCredentials(credentials, username) {

    const config = {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: 'Basic ' + credentials
        }
    };

    return fetch(ADMIN_DOMAIN_URL + '/check_auth?un=' + username, config)
        .then(response => {
            const jResponse = response.json();
            return jResponse;
        })
        .then(jResponse => {
            if (jResponse.status !== 'SUCCESS') {
                return Promise.reject(jResponse);
            }
            return jResponse;
        });
}


export const apiService = {
    getTestCategoryList,
    addTestCategory,
    getTestCategory,
    deleteTestCategory,
    addTest,
    getTest,
    getQuestions,
    deleteTest,
    addQuestion,
    deleteQuestion,
    getUserTests,
    generateUserTest,
    getUserTestForUser,
    getUserTestQuestionForUser,
    sendUserTest,
    updateUserTest,
    getUserTestQuestionnaire,
    deleteUserTest,
    sendUserTestEmail,
    checkCredentials,
    geSourceTest,
  saveUserAnswer
};