import React from 'react';
import PropTypes from 'prop-types';
import {TEST_METHODOLOGY} from '../services/testMethodologyService';

function SelectTestMethodology({methodology, onChange}) {
    return (
        <select className="form-control"
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange(e.target.value);
                }}>
          {TEST_METHODOLOGY.map((m,i) => {
            return <option key={'meth'+i} selected={methodology === m.value} value={m.value}>{m.name}</option>
          })}
            {/*<option selected={methodology === ''} value={''}>Без методики</option>*/}
            {/*<option selected={methodology === 'kettell-16'} value="kettell-16">16-ти факторный опросник Кеттелла</option>*/}
            {/*<option selected={methodology === 'standard'} value="standard">Стандарт (кол-во правильных ответов)</option>*/}
        </select>
    );
}

SelectTestMethodology.propTypes = {
    methodology: PropTypes.string,
    onChange: PropTypes.func
};

export default SelectTestMethodology;