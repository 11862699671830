export const KETTELL_SCAN = [
    {
        gender: 'men',
        agePeriod: [
            {
                minAge: 19,
                maxAge: 28,
                scales: [
                    {
                        scale: 'A',
                        values: '00001223445566788999999999'
                    },
                    {
                        scale: 'B',
                        values: '00001345678999999'
                    },
                    {
                        scale: 'C',
                        values: '00000001122334455667789999999999'
                    },
                    {
                        scale: 'E',
                        values: '00000001123344555667889999999999'
                    },
                    {
                        scale: 'F',
                        values: '0000001112233344556677889999999'
                    },
                    {
                        scale: 'G',
                        values: '000001122233455667889999'
                    },
                    {
                        scale: 'H',
                        values: '00011222333444555667788999999999'
                    },
                    {
                        scale: 'I',
                        values: '00012234455667789999999999'
                    },
                    {
                        scale: 'L',
                        values: '00001223445567789999999999'
                    },
                    {
                        scale: 'M',
                        values: '0000001223445566778999'
                    },
                    {
                        scale: 'N',
                        values: '00000011234556778999999'
                    },
                    {
                        scale: 'O',
                        values: '0000122334556677889999999999999'
                    },
                    {
                        scale: 'Q1',
                        values: '00000123345667889999999'
                    },
                    {
                        scale: 'Q2',
                        values: '0000122344556677889999999'
                    },
                    {
                        scale: 'Q3',
                        values: '00001123344566788999999'
                    },
                    {
                        scale: 'Q4',
                        values: '000011223344455666677889999999'
                    }
                ]
            },
            {
                minAge: 29,
                maxAge: 70,
                scales: [
                    {
                        scale: 'A',
                        values: '00001223445566788999999'
                    },
                    {
                        scale: 'B',
                        values: '001234567789999'
                    },
                    {
                        scale: 'C',
                        values: '0000000011122334456677889999'
                    },
                    {
                        scale: 'E',
                        values: '00000011223344556677889999999'
                    },
                    {
                        scale: 'F',
                        values: '000011222334445566778999999999'
                    },
                    {
                        scale: 'G',
                        values: '000001112223345566789'
                    },
                    {
                        scale: 'H',
                        values: '0000112223334445566677889999'
                    },
                    {
                        scale: 'I',
                        values: '00012334455667789999999'
                    },
                    {
                        scale: 'L',
                        values: '00012233455667889999999'
                    },
                    {
                        scale: 'M',
                        values: '000000112334556677889999999999'
                    },
                    {
                        scale: 'N',
                        values: '0000000122345567889999'
                    },
                    {
                        scale: 'O',
                        values: '0001223344556777889999'
                    },
                    {
                        scale: 'Q1',
                        values: '00000112344566788999999'
                    },
                    {
                        scale: 'Q2',
                        values: '000012233445667788999999'
                    },
                    {
                        scale: 'Q3',
                        values: '0000011223445667889999'
                    },
                    {
                        scale: 'Q4',
                        values: '011222334445566677889999999999'
                    }
                ]
            }

        ]
    },
    {
        gender: 'women',
        agePeriod: [
            {
                minAge: 19,
                maxAge: 28,
                scales: [
                    {
                        scale: 'A',
                        values: '0000011233444566788999'
                    },
                    {
                        scale: 'B',
                        values: '000001345678999'
                    },
                    {
                        scale: 'C',
                        values: '00000001122334455667788999999'
                    },
                    {
                        scale: 'E',
                        values: '0000122334455667788999'
                    },
                    {
                        scale: 'F',
                        values: '000000112223344455667789999999999'
                    },
                    {
                        scale: 'G',
                        values: '0000011223344566778999'
                    },
                    {
                        scale: 'H',
                        values: '00011222334445556677788999999'
                    },
                    {
                        scale: 'I',
                        values: '0000001223344567889999'
                    },
                    {
                        scale: 'L',
                        values: '001123445567788999999999'
                    },
                    {
                        scale: 'M',
                        values: '000000112334455667889999999999'
                    },
                    {
                        scale: 'N',
                        values: '00000012344566788999999'
                    },
                    {
                        scale: 'O',
                        values: '0000122344555667788999999999999'
                    },
                    {
                        scale: 'Q1',
                        values: '000012334566778999999999'
                    },
                    {
                        scale: 'Q2',
                        values: '000012234455667788999999'
                    },
                    {
                        scale: 'Q3',
                        values: '00000122334556788999999'
                    },
                    {
                        scale: 'Q4',
                        values: '00001122333445556667788999999'
                    }
                ]
            },
            {
                minAge: 29,
                maxAge: 70,
                scales: [
                    {
                        scale: 'A',
                        values: '0000011223345566788999'
                    },
                    {
                        scale: 'B',
                        values: '001234567789999'
                    },
                    {
                        scale: 'C',
                        values: '0000000011223344556667788999'
                    },
                    {
                        scale: 'E',
                        values: '0001223344556667788999999999999'
                    },
                    {
                        scale: 'F',
                        values: '00000112233444556677889999999'
                    },
                    {
                        scale: 'G',
                        values: '000000012233445567889'
                    },
                    {
                        scale: 'H',
                        values: '00011223334445566677788999999'
                    },
                    {
                        scale: 'I',
                        values: '0000001123345567789999'
                    },
                    {
                        scale: 'L',
                        values: '001223445667889999999999'
                    },
                    {
                        scale: 'M',
                        values: '000000012233455667889999999999'
                    },
                    {
                        scale: 'N',
                        values: '000000123344556788888'
                    },
                    {
                        scale: 'O',
                        values: '0000122334455667788999999999999'
                    },
                    {
                        scale: 'Q1',
                        values: '000012334566778999999999'
                    },
                    {
                        scale: 'Q2',
                        values: '000012233455667788999999'
                    },
                    {
                        scale: 'Q3',
                        values: '0000001123345567789999'
                    },
                    {
                        scale: 'Q4',
                        values: '00011222333445556677788999999'
                    }
                ]
            }

        ]
    }
];

export const SCALE_TEMPLATE = ['A','B','C','E','F','G','H','I','L','M','N','O','Q1','Q2','Q3','Q4'];