import React from 'react';
import PropTypes from 'prop-types';
import CarouselItem from "./CarouselItem";
import {checkMiddleQuestions} from "../services/testMethodologyService";
import moment from 'moment/moment';
import {apiService} from '../services/apiService';

function Carousel({questionList, onChange, onFinish, userTest, timeout}) {
  const [activeQuestion, setActiveQuestion] = React.useState(questionList[0]);
  const [questionIdx, setQuestionIdx] = React.useState(0);
  const [isFinish, setFinish] = React.useState(false);
  const [middleAnswerCount, setMiddleAnswerCount] = React.useState(0);
  const [disabledValue, setDisabledValue] = React.useState(undefined);
  const [timeIsOver, setTimeIsOver] = React.useState(false);

  const [seconds, setSeconds] = React.useState(timeout);
  const [timerActive, setTimerActive] = React.useState(timeout > 0);

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if(timeout > 0) {
      if (seconds > 0 && timerActive) {
        setTimeout(setSeconds, 1000, seconds - 1);
      } else {
        setTimeIsOver(true);
        setTimerActive(false);
        setFinish(true);
      }
    }
  }, [seconds, timerActive, timeout]);

  const onChangeOption = (question) => {
    let qList = questionList;
    qList[questionIdx] = question;
    setActiveQuestion(question);
    onChange(qList);

    //подсчитываем количество усредненных ответов (для оценки по Кеттеллу)
    if (userTest.methodology.includes('kettell-16')) {
      let middleCount = checkMiddleQuestions(qList);
      calculateDisabledValue(question, middleCount);
      setMiddleAnswerCount(middleCount);
    }
  };

  const testAgain = () => {
    setFinish(false);
    setQuestionIdx(0);
    setActiveQuestion(questionList[0]);
  };

  const calculateDisabledValue = (question, middleAnswerCount) => {
    let limit = undefined;
    if (userTest.methodology.includes('kettell-16')) {
      let qScale = JSON.parse(question.question.parameters).scale;
      if (qScale !== 'B' && middleAnswerCount >= 10) {
        limit = 1;
      }
    }
    setDisabledValue(limit);
  };

  return (
    <div className={'row justify-content-center'}>
      <div className={'col col-md-10 col-sm-12 p-0'}>
        {error && <div className={'carousel'}><p>{error}</p></div>}
        {!error && <div className={'carousel'}>
          {timeout > 0 &&
          <div className="d-flex justify-content-end mb-3">
            <small className={'text-muted'}>
              Оставшееся время <strong>{moment(seconds * 1000).utcOffset(0).format('HH:mm:ss')}</strong>
              </small>
            <br/>
          </div>
          }
          <div className="d-flex justify-content-between">
            <small
              className={'text-muted'}>{'Вопрос ' + (questionIdx + 1) + ' из ' + questionList.length}</small>
            {userTest.methodology.includes('kettell-16') &&
            <small className={'text-muted'}
                   style={{color: middleAnswerCount >= 10 ? 'orange' : '#6c757d'}}>
              {'Количество усредненных ответов ' + (middleAnswerCount) + ' из 10'}
              </small>}
          </div>
          {timeIsOver && !isFinish &&
            <div>
              <h5 className={'justify-content-center mt-5 mb-5'}>Тестирование окончено</h5>
              <p>Время, отведенное на опрос (тестирование) закончилось. Ваши ответы были сохранены и отправлены на проверку.</p>
              <div className="d-flex justify-content-center mt-3">
                <button className={'btn btn-orange'}
                        onClick={() => {
                          setTimerActive(false);
                          onFinish();
                        }}>Закончить тестирование
                </button>
              </div>
            </div>
          }
          {isFinish && !timeIsOver &&
          <div>
            <h5 className={'justify-content-center mt-5 mb-5'}>Тестирование окончено</h5>
            <hr/>
            <div className={'d-flex justify-content-between mt-4'}>
              <button className={'btn btn-orange-bordered'}
                      onClick={() => testAgain()}>Пройти заново
              </button>
              <button className={'btn btn-orange'}
                      onClick={() => {
                        setTimerActive(false);
                        onFinish();
                      }}>Закончить тестирование
              </button>
            </div>
          </div>}
          {!isFinish && <div className={'question-answer'}>
            <CarouselItem item={activeQuestion} key={'item-' + activeQuestion.id}
                          onChangeOption={q => onChangeOption(q)} limitValue={disabledValue}/>
          </div>}
          {!isFinish && <div className={'action-block'}>
            <button type={'button'} className={'icon-btn prev-btn'}
                    onClick={() => {
                      let qIdx = questionIdx > 0 ? (questionIdx - 1) : 0;
                      setQuestionIdx(qIdx);
                      let q = questionList[qIdx];
                      setActiveQuestion(q);
                      calculateDisabledValue(q, middleAnswerCount);
                    }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 70.31"><title>Предыдущий вопрос</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M67.34,1.06a3.55,3.55,0,1,0-5,5L87.85,31.6H3.53A3.51,3.51,0,0,0,0,35.13a3.55,3.55,0,0,0,3.53,3.58H87.85L62.3,64.22a3.62,3.62,0,0,0,0,5,3.53,3.53,0,0,0,5,0l31.6-31.6a3.47,3.47,0,0,0,0-5Z"/>
                  </g>
                </g>
              </svg>
            </button>
            <span>{questionIdx + 1 + ' / ' + questionList.length}</span>
            <button type={'button'} className={'icon-btn next-btn ' + (!activeQuestion.answer ? 'disabled' : '')}
                    disabled={!activeQuestion.answer}
                    style={{border: (!activeQuestion.answer || activeQuestion.answer === '' ? 'none' : '1px solid #FF8C00')}}
                    onClick={() => {
                      apiService.saveUserAnswer(activeQuestion).then(
                        resp => {
                          console.log(resp);
                        },
                        error => {
                          console.log(error);
                          setError("Произошла ошибка, при сохранении вашего ответа. Обратитесь, пожалуйста к администратору. Приносим свои извинения!");
                        }
                      );
                      if (questionIdx < (questionList.length - 1)) {
                        let qIdx = questionIdx + 1;
                        setQuestionIdx(qIdx);
                        let q = questionList[qIdx];
                        setActiveQuestion(q);
                        calculateDisabledValue(q, middleAnswerCount);
                      } else {
                        setFinish(true);
                      }
                    }}>
              <label style={{marginRight: '0.6rem'}}>Следующий вопрос</label>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 70.31"><title>Следующий вопрос</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M67.34,1.06a3.55,3.55,0,1,0-5,5L87.85,31.6H3.53A3.51,3.51,0,0,0,0,35.13a3.55,3.55,0,0,0,3.53,3.58H87.85L62.3,64.22a3.62,3.62,0,0,0,0,5,3.53,3.53,0,0,0,5,0l31.6-31.6a3.47,3.47,0,0,0,0-5Z"/>
                  </g>
                </g>
              </svg>
            </button>
          </div>}
        </div>}

      </div>
    </div>
  );
}

Carousel.propTypes = {
  questionList: PropTypes.array,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
  userTest: PropTypes.object,
  timeout: PropTypes.number
};

export default Carousel;