import React from 'react';
import PropTypes from 'prop-types';
import {Link, useLocation} from "react-router-dom";

function Header() {
    const location = useLocation();
    const [show, setShow] = React.useState(false);
    return (
        <header>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <Link to={'/admin/tests/test_categories'} className="navbar-brand">
                    {/*<a className="navbar-brand" href="/admin/tests">*/}
                        <div className="logo-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100" x="0px" y="0px">
                                <path d="M43.47,36.22H68a3,3,0,0,0,0-6H43.47a3,3,0,0,0,0,6Z"></path>
                                <path d="M29,26.58l-7.68,7.83-2.87-2.83a3,3,0,1,0-4.21,4.28l5,4.93a3,3,0,0,0,2.1.86h0a3,3,0,0,0,2.11-.9l9.78-10A3,3,0,1,0,29,26.58Z"></path>
                                <path d="M29,45.83l-7.68,7.83-2.87-2.83a3,3,0,1,0-4.21,4.28l5,4.93a3,3,0,0,0,2.1.86h0A3,3,0,0,0,23.5,60l9.78-10A3,3,0,1,0,29,45.83Z"></path>
                                <path d="M80.85,48.24V16.66a13,13,0,0,0-13-13H15.16a13,13,0,0,0-13,13V63.71a13,13,0,0,0,13,13H49A24.67,24.67,0,1,0,80.85,48.24ZM15.16,9.66H67.85a7,7,0,0,1,6.76,5.2H8.4A7,7,0,0,1,15.16,9.66Zm-7,54.05V20.86H74.85V47.07c-.56,0-1.12-.06-1.68-.06a24.51,24.51,0,0,0-10.82,2.5,3,3,0,0,0-.33,0H43.47a3,3,0,0,0,0,6H54.59a24.55,24.55,0,0,0-6.06,15.24H15.16A7,7,0,0,1,8.16,63.71Zm65,26.63A18.67,18.67,0,1,1,91.84,71.67,18.69,18.69,0,0,1,73.17,90.34Z"></path>
                                <path d="M78,65.83l-7.68,7.83-2.87-2.83a3,3,0,1,0-4.21,4.28l5,4.93a3,3,0,0,0,2.1.86h0A3,3,0,0,0,72.5,80l9.78-10A3,3,0,1,0,78,65.83Z"></path>
                            </svg>
                        </div>
                    {/*</a>*/}
                    </Link>
                    <button className={'navbar-toggler' + (show ? ' collapsed' : '')} type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded={(show ? 'false' : 'true')} aria-label="Toggle navigation"
                            onClick={() => setShow(!show)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={'collapse navbar-collapse' + (show ? ' show' : '')} id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className={location.pathname.startsWith('/admin/tests') ? 'nav-item active' : 'nav-item'}>
                                <Link to={'/admin/tests/test_categories'} className="nav-link">
                                    Тесты
                                </Link>
                                {/*<a className="nav-link" href="/admin/tests">Тесты <span className="sr-only">(current)</span></a>*/}
                            </li>
                            <li className={location.pathname.startsWith('/admin/user_testing') ? 'nav-item active' : 'nav-item'}>
                                <Link to={'/admin/user_testing'} className="nav-link">
                                    Тестирование
                                </Link>
                                {/*<a className="nav-link" href="/admin/users_tests">Тестирование</a>*/}
                            </li>
                        </ul>
                        <form className="d-flex">
                            <Link to={'/login'} className="nav-link btn btn-orange">
                                Выйти
                            </Link>
                        </form>
                    </div>
                </nav>
            </div>
        </header>
    );
}

Header.propTypes = {};

export default Header;