import React from 'react';
import svgShow from '../sources/img/show.svg';
import svgHide from '../sources/img/hide.svg';
import {authorizeUser, logout} from "../services/login.service";
import {useHistory} from 'react-router-dom';

function LoginPage() {

    const [error, setError] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    let history = useHistory();

    React.useEffect(() => {
        logout();
    },[]);

    const login = () => {
        setSubmitted(true);
        if(username !== '' && password !== ''){
            authorizeUser(username, password).then(
                response => {
                    if(response.status === 'SUCCESS'){
                        history.push('/admin');
                    }
                },error => {
                    setError(error);
                }
            )
        }
    };


    return (
        <div className={'container-fluid'}>
            <div className={'row test-me-header'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col col-md-6 col-sm-12 p-0'}>
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <div className={'logo-2 mr-2'}/>
                                <div>
                                    <h3 className={'logo-text'}>Дистанционная система опросов</h3>
                                    <h5><b>Вход в административный портал</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className={'test-me-container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col col-md-6 col-sm-12 p-0'}>
                            <form className={'mt-5 testing-user ' + (submitted ? 'was-validated' : '')}>
                                <div className="form-group">
                                    <label htmlFor="lastName">Имя пользователя</label>
                                    <input type="text" className="form-control" id="username" value={username}
                                           onChange={(e) => setUsername(e.target.value)} required/>
                                    <div className="invalid-feedback">
                                        Введите имя пользователя
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Пароль</label>
                                    <div className={'input-group'}>
                                        <input type={showPassword ? 'text' : 'password'} className="form-control" id="password" value={password}
                                               onChange={(e) => setPassword(e.target.value)} required/>
                                        <button type="button" className={'btn btn-orange-bordered'} style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
                                                onClick={() => setShowPassword(!showPassword)}>
                                            <img style={{width: 20, height: 20}} src={showPassword ? svgShow : svgHide}
                                                 alt={'Show symbols'}/>
                                        </button>
                                        <div className="invalid-feedback">
                                            Введите пароль
                                        </div>
                                    </div>
                                </div>
                                {/*<hr className={'mt-5 mb-4'}/>*/}
                                <div className={'d-flex justify-content-center mt-5 mb-4'}>
                                    <button type="button" onClick={() => {
                                        login();
                                    }} className="btn btn-orange form-control justify-content-center">
                                        <span style={{fontWeight: '500'}}>Войти</span>
                                    </button>
                                </div>
                                {error !== '' &&
                                <div className="alert alert-danger mt-5" role="alert">
                                    {error}
                                </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

LoginPage.propTypes = {};

export default LoginPage;