import React from 'react';
// import Portal from './Portal';
import PropTypes from 'prop-types';
import ClickOutside from './ClickOutside';


function Modal({title, isOpen, onCancel, children, isClosable, isCustomBody, footer, onSubmit, isConfirm, width, height, overflow}) {
    return (
        <div className={'modal fade' + (isOpen ? ' show':'')} style={{display: isOpen ? 'block' : 'none'}}>
            <div className={'modal-dialog'}>
                <ClickOutside onClick={() => onCancel()} disabled={!isClosable}>
                    <div className={'modal-content'}>
                        <div className={'modal-header'}>
                            <h5 className={'modal-title'}>{title}</h5>
                            {isClosable && <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>onCancel()}>
                                <span aria-hidden="true">&times;</span>
                            </button>}
                        </div>
                        {!isCustomBody && children}
                        {isCustomBody &&
                        <div className={'modal-body'}>
                            {children}
                        </div>
                        }
                        {footer &&
                        <div className={'modal-footer'}>
                        <button type={'button'} className={'ems-btn default'} onClick={()=>onCancel()}>{isConfirm ? 'Нет':'Закрыть'}</button>
                        {onSubmit &&
                        <button type={'button'} className={'ems-btn success ml1'} onClick={() => onSubmit()}>{isConfirm ? 'Да' : 'Ок'}</button>
                        }
                        </div>}
                    </div>
                </ClickOutside>
            </div>
        </div>
    );

    {/*<>*/}
    {/*{isOpen &&*/}
    {/*<Portal>*/}
    {/*<div className={'modal-layout open'}>*/}
    {/*<ClickOutside onClick={() => onCancel()} disabled={!isClosable}>*/}
    {/*<div className={'modal-container'}>*/}
    {/*{isClosable && <button type='button' onClick={()=>onCancel()} className={'close-btn'}>&times;</button>}*/}
    {/*{title && <div className={'modal-header'}>*/}
    {/*<div className={'modal-title'}>{title}</div>*/}
    {/*</div>}*/}
    {/*<div className={'modal-body'} style={{maxWidth: width, maxHeight: height, overflow: overflow}}>*/}
    {/*{children}*/}
    {/*</div>*/}
    {/*{footer &&*/}
    {/*<div className={'modal-footer'}>*/}
    {/*<button type={'button'} className={'ems-btn default'} onClick={()=>onCancel()}>{isConfirm ? 'Нет':'Закрыть'}</button>*/}
    {/*{onSubmit &&*/}
    {/*<button type={'button'} className={'ems-btn success ml1'} onClick={() => onSubmit()}>{isConfirm ? 'Да' : 'Ок'}</button>*/}
    {/*}*/}
    {/*</div>}*/}
    {/*</div>*/}
    {/*</ClickOutside>*/}
    {/*</div>*/}
    {/*</Portal>*/}
    {/*}*/}
    {/*</>*/}
}

Modal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    isClosable: PropTypes.bool,
    footer: PropTypes.bool,
    isConfirm: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    overflow: PropTypes.string,
    isCustomBody: PropTypes.bool
};

Modal.defaultProps = {
    title: null,
    isOpen: false,
    onCancel: () => {},
    onSubmit: () => {},
    children: null,
    isClosable: true,
    footer: true,
    isConfirm: false,
    width: 'auto',
    height: 'auto',
    overflow: 'unset',
    isCustomBody: false
};


export default Modal;