import React from 'react';
import PropTypes from 'prop-types';

function CarouselItem({item, onChangeOption, limitValue}){

    const onCheck = (isChecked, optionId) => {
        let answer = '';
        let answerValue = '';
        let aOptions = item.question.answerOptions.map((op, i) => {
                if (i === optionId){
                    answer = op.answer;
                    answerValue = op.value;
                    return {...op, checked: true};
                }else{
                    return {...op, checked: false};
                }
            });
        onChangeOption({...item, answer: answer, answerValue: answerValue, question: {...item.question, answerOptions: aOptions}});
    };

    return <div style={{minWidth: '100%'}}>

        <h5 className={'question'}>{item.question.question}</h5>
        <div className={'answer-list'}>
            <small className={'text-muted mb-2'}>Варианты ответов</small>
            {item.question.answerOptions.map((a, i) => {
                return <div className="form-check" key={'option-' + i}>
                    <button type={'button'} key={'check-' + i}
                            // disabled={limitValue === Number.parseInt(a.value)}
                            style={{cursor: limitValue === a.value ? 'not-allowed' : 'pointer', backgroundColor: limitValue === Number.parseInt(a.value) ? '#ececec' : 'white'}}
                            className={'btn answer-btn' + (a.answer === item.answer ?  ' checked' : '')/*(a.checked ? ' checked' : '')*/}
                            onClick={() => {
                                if(limitValue === Number.parseInt(a.value)){
                                    alert('По условиям теста Вы не можете ответить усредненным ответом более десяти раз.');
                                }else {
                                    onCheck(a.checked, i);
                                }
                            }}>
                        <span className={'answer-number mr-2'}>{(i+1)+'.'}</span>
                        {a.answer}
                    </button>
                </div>;
            })}
        </div>
    </div>;
}


CarouselItem.propTypes = {
    item: PropTypes.object,
    onChangeOption: PropTypes.func,
    limitValue: PropTypes.number
};

export default CarouselItem;